import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React, { useState } from "react"
import Emotioneel from "../assets/images/icon/emotioneel-deel.inline.svg"
import Juridisch from "../assets/images/icon/juridisch.inline.svg"
import Zakelijk from "../assets/images/icon/zakelijk.inline.svg"
import Accordion from "../components/accordion"
import BackgroundImageWrapper from "../components/backgroundImage"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Testimonials from "../components/testimonials"
import Video from "../components/video"

const Echtscheiding = () => {
  const [error, setError] = useState(false)

  const [user, setUser] = useState({
    name: "",
    email: "",
    contactType: "",
    phoneNumber: "",
    contactTime: "",
  })

  const onChangeHandler = event => {
    setUser(prevState => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }))
  }

  const onSubmit = event => {
    event.preventDefault()

    if (!user.name || !user.email) {
      setError(true)
    }
  }
  return (
    <Layout>
      <Seo title="Mediation-bij-een-echtscheiding" />
      <BackgroundImageWrapper hero="hero2" position="center" height="461px">
        <div className="absolute inset-0 bg-gray opacity-60" />
        <div className="px-4 py-150px flex justify-center flex-col text-center relative">
          <h1 className="text-4xl font-bold text-white mb-10px">
            Mediation bij echtscheiding
          </h1>
          <p className="text-lg font-normal text-white">
            Voor een oplossing waar beiden mee verder kunnen
          </p>
          <div className="flex mt-15px justify-center">
            <Link
              to="/contact"
              className="border-2 border-white py-10px px-14px text-lg text-white font-medium underline hover:bg-white hover:text-black transition-all ease-out duration-100"
            >
              Kennismaking aanvragen
            </Link>
          </div>
        </div>
      </BackgroundImageWrapper>
      <section className="py-75px px-5 text-center">
        <div className="max-w-700px mx-auto">
          <h2 className="text-27px pb-15px leading-1.2 font-normal text-primary">
            Waarom mediation bij een echtscheiding?
          </h2>
          <p className="text-lg leading-1.625 font-normal text-primary mb-30px">
            Ruzie met je partner heeft veel impact. Op iedereen. Je kunt er niet
            aan ontsnappen. Je wilt dat anderen zo min mogelijk last hebben van
            de situatie. Zeker als er kinderen zijn. Dat kan alleen als je
            blijft praten. Mediation is geen relatietherapie. Bij scheiding is
            mediation er op gericht om zo goed mogelijk uit elkaar te gaan.
            Mediation is er voor mensen die getrouwd zijn en voor samenwoners.
          </p>

          <Video
            className="h-393px max-w-700px"
            videoSrcURL="https://www.youtube.com/embed/Aap7v2Lc8z4"
            videoTitle="Familiemediation bij Eerstmediation.nl"
          />
        </div>
      </section>

      <section className="py-75px px-5">
        <div className="max-w-700px mx-auto">
          <h2 className="text-27px pb-15px leading-1.2 font-normal text-primary">
            De scheidingsprocedure
          </h2>
          <p className="text-lg leading-1.625 font-normal text-primary mb-30px">
            Na een gezamelijk gratis kennismakingsgesprek start de mediation. De
            mediation bestaat uit drie delen. Er zijn doorgaans 2 tot 5
            gesprekken nodig om de hele mediation af te ronden.
          </p>
        </div>
      </section>

      <section className="py-75px px-5">
        <div className="max-w-700px mx-auto">
          <div className="flex flex-col gap-y-35px">
            <div className="flex items-center gap-x-5">
              <div className="w-130px flex justify-center">
                <div className="w-96px h-96px">
                  <Emotioneel />
                </div>
              </div>
              <div className="w-570px">
                <h3 className="text-2xl text-primary mb-10px">
                  Emotioneel deel
                </h3>
                <p className="text-primary text-lg">
                  Er is aandacht voor de reden van de scheiding. Er wordt
                  gesproken over de wederzijdse band met de kinderen en de wijze
                  waarop deze zo goed mogelijk kan blijven. De huwelijksbelofte
                  wordt omgezet in een{" "}
                  <Link to="/blog/ouderschapsbelofte-bij-scheiding" className="text-link cursor-pointer">
                    {" "}
                    ouderschapsbelofte
                  </Link>
                  . De mediator organiseert desgewenst ook kindgesprekken.
                </p>
              </div>
            </div>

            <div className="flex items-center gap-x-5">
              <div className="w-130px flex justify-center">
                <div className="w-75px h-100px">
                  <Zakelijk />
                </div>
              </div>
              <div className="w-570px">
                <h3 className="text-2xl text-primary mb-10px">Zakelijk deel</h3>
                <p className="text-primary text-lg">
                  De mediator zorgt dat alle onderwerpen aan bod komen en dat u
                  alle informatie krijgt op grond waarvan u een keuze kunt
                  maken. De verschillende (juridische) mogelijkheden worden
                  doorgenomen en de fiscale gevolgen toegelicht. U mag achteraf
                  niet voor verrassingen komen te staan. Er wordt onderhandeld
                  en de afspraken worden door de mediator vastgelegd in een
                  <Link to="/" className="cursor-pointer text-link">
                    {" "}
                    echtscheidingsconvenant
                  </Link>{" "}
                  en{" "}
                  <Link to="/" className="text-link cursor-pointer">
                    ouderschapsplan
                  </Link>
                  . Met name het convenant is een belangrijk juridisch stuk waar
                  u ook echt aan vast zit.
                </p>
              </div>
            </div>

            <div className="flex items-center gap-x-5">
              <div className="w-130px flex justify-center">
                <div className="h-100px w-100px">
                  <Juridisch />
                </div>
              </div>
              <div className="w-570px">
                <h3 className="text-2xl text-primary mb-10px">
                  Juridisch deel
                </h3>
                <p className="text-primary text-lg">
                  Als de stukken getekend zijn zal de mediator, die bij
                  Eerstmediation.nl tevens advocaat is, namens u beiden het
                  echtscheidingsverzoek indienen bij de rechtbank. De
                  mediator-advocaat wikkelt dus het hele proces voor u af. Als
                  de rechtbank een echtscheidingsbeschikking heeft afgegeven
                  zorgt de mediator-advocaat voor de inschrijving in het
                  huwelijksregister.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-75px px-5">
        <div className="max-w-700px mx-auto">
          <h2 className="text-27px pb-15px leading-1.2 font-normal text-primary">
            Veelgestelde vragen
          </h2>
          <div className="flex flex-col gap-y-2">
            <Accordion
              title="Samen naar de mediator of ieder een eigen advocaat?"
              text="Samen naar de mediator heeft de voorkeur. Je wilt er samen uit komen en de onderlinge relatie zo min mogelijk beschadigen. Zeker als er kinderen zijn is dit erg belangrijk. Je kiest voor een eigen advocaat als:"
              list={[
                "het echtscheidingsverzoek al is ingediend",
                "er geen wil is om er samen uit te komen",
                "er geen onderhandelingsruimte is",
                "het te ver geëscaleerd is",
                "er sprake is van een psychische stoornis bij één van beiden",
                "er sprake is van herhaaldelijk huiselijk geweld",
              ]}
            />

            <Accordion
              title="Wat is het verschil tussen een scheiding bij de mediator of met een eigen advocaat?"
              list={[
                "bij de mediator is meer ruimte voor emotie",
                "de mediator is er voor u beiden, een eigen advocaat is er voor u alleen",
                "bij mediation is de procedure vooraf duidelijk",
                "bij een eigen advocaat hangt het verloop mede af van de andere partij",
                "bij mediation heb je meer invloed op het resultaat omdat je kunt onderhandelen",
                "mediation gaat sneller",
                "mediation is goedkoper",
              ]}
            />

            <Accordion
              title="Waar moet ik op letten bij het kiezen van een scheidingsmediator?"
              list={[
                "Kies een MfN registermediator die ook op toevoegingsbasis werkt. Deze mediators staan vermeld in het register van de Raad voor Rechtsbijstand. Heel veel mensen komen in aanmerking voor een toevoeging (gefinancierde rechtshulp) zonder dat ze dat weten. Dat scheelt veel geld. Het MfN register waarborgt de kwaliteit. Zowel de rechtbank als de Raad voor Rechtsbijstand werken alleen met MfN Registermediators.",
                "Kies een familiemediator met een juridische achtergrond. Het echtscheidingsconvenant is een juridisch stuk waar u ook echt aan vast zit. Het is belangrijk dat dit door een jurist is opgesteld. Kijk op de website of linkedinpagina naar de achtergrond van mediator. Als u geen informatie kunt vinden is dit geen goed teken. Alleen wanneer de mediator ook advocaat is kan deze het hele traject voor u afhandelen.",
              ]}
            />

            <Accordion
              title="Praat de mediator ook met de kinderen?"
              text="De mediator praat, als ouders en kinderen dat willen, met de kinderen alleen. De volgende onderwerpen worden bijvoorbeeld besproken:"
              list={[
                "Hoe gaat het met je?",
                "Waar heb je last van?",
                "Hoe zie je de toekomst?",
                "Wat zijn je wensen?",
                "Met wie praat je over de scheiding?",
                "Welk advies wil je je ouders meegeven?",
              ]}
              footer="De mediator maakt een verslagje van dit gesprek en legt dit eerst aan de kinderen voor. De kinderen bepalen wat er wel en niet in komt te staan. Na goedkeuring van de kinderen gaat het verslagje naar de ouders. De ouders kunnen hiermee rekening houden bij het maken van de afspraken."
            />
          </div>
          <p className="text-lg text-primary my-30px">
            Andere vraag? Neem gerust &nbsp;
            <Link to="/contact" className="text-link cursor-pointer underline">
              contact
            </Link>
            &nbsp; op.
          </p>
        </div>
      </section>

      <section className="py-75px px-5 bg-light-green text-center ">
        <div className="max-w-700px mx-auto">
          <h2 className="text-27px pb-15px leading-1.2 font-normal mb-30px text-white">
            Gratis kennismaking aanvragen
          </h2>
          <div className="flex gap-x-2 mt-15px justify-center">
            <Link
              to="/contact"
              className="border-2 border-white py-10px px-14px text-lg text-white font-medium underline hover:bg-white hover:text-black transition-all ease-out duration-100"
            >
              Contact Opnemen
            </Link>
          </div>
        </div>
      </section>

      <section className="py-75px px-5 text-center">
        <div className="max-w-700px mx-auto">
          <h2 className="text-27px pb-15px leading-1.2 font-normal text-primary mb-30px">
            Reacties van klanten
          </h2>
          <Testimonials />
          <Link
            to="/recensies"
            className="inline-block py-10px px-14px text-lg underline text-link hover:text-primary"
          >
            Meer reacties
          </Link>
        </div>
      </section>

      <section className="py-75px text-center">
        <div className="max-w-700px mx-auto">
          <StaticImage
            src="../assets/images/eerstmediation-thumbnail-big.png"
            placeholder="blurred"
            className="h-98px w-98px mb-5"
            alt="eerstmediation thumbnail big"
          />
          <h2 className="text-27px mb-5 leading-1.2 font-normal text-primary">
            Over het kantoor
          </h2>
          <p className="text-lg leading-1.625 font-normal text-primary ">
            Eerstmediation.nl is een Mediation & Advocatenkantoor dat is
            gespecialiseerd in mediation bij echtscheidingen en
            arbeidsconflicten in het Land van Cuijk en de regio Nijmegen.
          </p>
          <Link
            to="/over-het-kantoor"
            className="inline-block py-10px px-14px text-lg underline text-link hover:text-primary"
          >
            Meer over het kantoor
          </Link>
        </div>
      </section>
    </Layout>
  )
}

export default Echtscheiding
