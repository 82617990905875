import React, { useState } from "react"
import SortDown from "../assets/images/icon/sort-down-solid.inline.svg"

const Accordion = ({ title, text, footer, list }) => {
  const [isActive, setIsActive] = useState(false)

  return (
      <div>
        <div
          className="py-10px px-15px bg-tertiary flex items-center cursor-pointer relative"
          onClick={() => setIsActive(!isActive)}
        >
          <div className={`absolute left-2 ${isActive ? "rotate-0" : "-rotate-90"} transform duration-700`}>
            <SortDown />
          </div>
          <strong className="text-lg text-primary leading-1.625 font-bold pl-4">
            {title}
          </strong>
        </div>
        {isActive && (
          <div className="py-10px px-15px">
            <p className="text-lg text-primary mb-30px">{text}</p>
            <ul className="list-disc pl-10 mb-30px">
              {list && list.length &&
                list.map(item => (
                  <li className="text-lg text-primary">{item}</li>
                ))}
            </ul>
            <p className="text-lg text-primary mb-30px">{footer}</p>
          </div>
        )}
      </div>
  )
}

export default Accordion
